<template>
    <div class="mt-4 table_container">
        <table class="table list-content">
            <thead class=" ">
            <tr>
                <th class="" style="text-align: center;">Referral</th>
                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="referral_sorting = (referral_sorting === 'clicks_asc') ? 'clicks_desc': 'clicks_asc'">
                    Clicks

                    <i v-if="referral_sorting==='clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="referral_sorting==='clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>

                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="referral_sorting = (referral_sorting === 'unique_clicks_asc') ? 'unique_clicks_desc': 'unique_clicks_asc'">
                    Unique Clicks

                    <i v-if="referral_sorting==='unique_clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="referral_sorting==='unique_clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>

                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="referral_sorting = (referral_sorting === 'conversions_asc') ? 'conversions_desc': 'conversions_asc'">
                    Conversions

                    <i v-if="referral_sorting==='conversions_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="referral_sorting==='conversions_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>
                </th>
                <th class="cursor_pointer sorted_row" style="text-align: center;">
                    Conv. rate
                </th>

            </tr>
            </thead>

            <tbody class="list-content-body">

            <tr v-if="statistics.aggregations.visited_from_domains.buckets.length == 0">
                <td colspan="9">
                    <div class="col">
                        <p class="no-rows text-center">
                            <no-events-available></no-events-available>
                        </p>
                    </div>
                </td>
            </tr>
            <template v-else>
                <tr v-for="analytic in getVisitedFromList">
                    <td>
                        {{analytic.key}}
                    </td>
                    <td style="text-align: center;">{{analytic.doc_count}}</td>
                    <td style="text-align: center;">{{analytic.unique_clicks.value}}</td>
                    <!--<td style="text-align: center;">
                        {{analytic.bounce}}%
                    </td>-->
                    <td style="text-align: center;">
                        {{analytic.clicked_conversions.doc_count}}
                    </td>
                    <td style="text-align: center;">

                        {{Math.round((analytic.clicked_conversions.doc_count / analytic.doc_count)*100)}}%
                    </td>
                </tr>
            </template>

            </tbody>

        </table>
    </div>

</template>
<script>
import orderBy from 'lodash/orderBy'

export default ({
  data () {
    return {
      referral_sorting: 'clicks_desc'
    }
  },
  props: {
    statistics: {}
  },
  computed: {
    getVisitedFromList () {
      if (this.statistics.aggregations.visited_from_domains.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.referral_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_domains.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    }
  }
})

</script>
