<template>
    <div class="mt-4 table_container">
        <table class="table list-content">
            <thead class=" ">
            <tr>
                <th style="text-align: left; padding-left: 14px;">Country

                </th>
                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="country_sorting = (country_sorting === 'clicks_asc') ? 'clicks_desc': 'clicks_asc'">
                    Clicks

                    <i v-if="country_sorting==='clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="country_sorting==='clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>

                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="country_sorting = (country_sorting === 'unique_clicks_asc') ? 'unique_clicks_desc': 'unique_clicks_asc'">
                    Unique Clicks

                    <i v-if="country_sorting==='unique_clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="country_sorting==='unique_clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>

                <th v-if="!forBio" class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="country_sorting = (country_sorting === 'conversions_asc') ? 'conversions_desc': 'conversions_asc'">
                    Conversions

                    <i v-if="country_sorting==='conversions_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="country_sorting==='conversions_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>
                </th>
                <th v-if="!forBio" class="cursor_pointer sorted_row" style="text-align: center;"
                >
                    Conv. rate
                </th>

            </tr>
            </thead>

            <tbody class="list-content-body">

            <tr v-if="statistics.aggregations.countries.buckets.length === 0">
                <td colspan="9">
                    <div class="col">
                        <p class="no-rows text-center">
                            <no-events-available></no-events-available>
                        </p>
                    </div>
                </td>
            </tr>
            <template v-else>
                <tr v-for="analytic in getCountriesList">
                    <td class="" style="text-align: left !important; padding-left: 14px !important; width: 20%;">
                      <img :src="computeCountryFlags(analytic.key)" class="mr-1" style="width: 30px;">
                      {{computeKeyAnalytics(analytic.key)}}
                    </td>
                    <td style="text-align: center; width: 20%;">{{analytic.doc_count}}</td>
                    <td style="text-align: center; width: 20%;">{{analytic.unique_clicks.value}}</td>
                    <!--<td style="text-align: center;">
                        {{analytic.bounce}}%
                    </td>-->
                    <td v-if="!forBio" style="text-align: center; width: 20%;">
                        {{analytic.clicked_conversions.doc_count}}
                    </td>
                    <td v-if="!forBio" style="text-align: center; width: 20%;">
                        {{Math.round((analytic.clicked_conversions.doc_count / analytic.doc_count) * 100)}}%
                    </td>
                </tr>
            </template>

            </tbody>

        </table>
    </div>

</template>
<script>
import orderBy from 'lodash/orderBy'

export default ({
  data () {
    return {
      country_sorting: 'clicks_desc'
    }
  },
  props: {
    statistics: {},
    forBio: false
  },
  computed: {
    getCountriesList () {
      if (this.statistics.aggregations.countries.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.country_sorting)
        let results = orderBy(this.statistics.aggregations.countries.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    }
  }
})

</script>
