<template>
  <div>
    <transition name="slide">
      <div class="clear">

        <div class="pb-4 white_box overview_block">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center">
              <div class="left">
                <h2>Overall Statistics</h2>
              </div>
              <!--<div class="right ml-auto " >-->
              <!--right-->
              <!--</div>-->

            </div>
            <div class="box_content">
              <template v-if="loader">
                <clip-loader :size="size" :color="color"></clip-loader>
              </template>
              <template v-else>
                <div class="inline_boxes  d-flex align-items-center">
                  <div class="box_icon  ">
                    <div class="box_icon_inner">
                      <div class="box_left">
                        <p class="top_text">Clicks</p>
                        <h3>{{ commaSeparatedNumber(statistics.hits.total) }}</h3>
                        <p class="small_text">
                          <template
                            v-if="$route.params.type === 'brand' ||
                                                         $route.params.type === 'call-to-action' ||
                                                          $route.params.type == null">(From
                            {{ commaSeparatedNumber(statistics.account_statistics.links) }}
                            Link{{ statistics.account_statistics.links > 1 ? 's' : '' }})
                          </template>
                          <template v-else>Total No. of Clicks</template>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="box_icon pl-2 ">
                    <div class="box_icon_inner">
                      <div class="box_left">
                        <p class="top_text">Unique Clicks</p>
                        <h3>{{ commaSeparatedNumber(statistics.aggregations.unique_clicks.value) }}</h3>
                        <p class="small_text">
                          Total No. of Unique Clicks
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-if="analytics_type!='bio_links'" class="box_icon pl-2 ">
                    <div class="box_icon_inner">
                      <div class="box_left">
                        <p class="top_text">Conversion</p>
                        <h3>{{
                            commaSeparatedNumber(statistics.aggregations.clicked_conversions.doc_count)
                          }}</h3>
                        <p class="small_text">
                          From {{ statistics.hits.total }} Clicks
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="analytics_type!='bio_links'" class="box_icon pl-2 ">
                    <div class="box_icon_inner">
                      <div class="box_left">
                        <p class="top_text">Conv. rate</p>
                        <h3>{{ getStatisticsConversionRate }}%</h3>
                        <p class="small_text">
                          Conversion rate
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="box_icon pl-2 "
                       v-if="$route.params.type == 'link' || $route.params.type == 'call-to-action'">
                    <div class="box_icon_inner">
                      <div class="box_left">
                        <p class="top_text">Bounce Rate</p>
                        <h3>{{ getAnalyticsDetail.data.bounces }}%</h3>
                        <p class="small_text">
                          Bounce Rate
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

              </template>
            </div>
          </div>

        </div>
        <div class="d-flex align-items-start ">
          <div class="pb-4 white_box pr-3" style="width: 100%;">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Clicks Performance</h2>

                </div>
                <div class="right ml-auto">
                                <span class="toggle_link" data-toggle="collapse" data-target="#clickPerformance">
                                      <span>Hide</span>
                                     <span>Show</span>
                                </span>
                </div>

              </div>
              <div class="box_content collapse show" id="clickPerformance">
                <template v-if="loader">
                  <clip-loader :color="color" :size="size"></clip-loader>
                </template>
                <template v-else-if="activity.clicks" style="width: 100%;">
                  <highcharts :options="clicks_chart" ref="clicks_chart"></highcharts>
                </template>
                <template v-else>
                  <no-events-available></no-events-available>
                </template>
              </div>
            </div>

          </div>

          <div v-if="analytics_type!='bio_links'" class="pb-4 white_box pl-3 " style="width: 100%;">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Conversions Performance</h2>

                </div>
                <div class="right ml-auto">
                                <span class="toggle_link" data-toggle="collapse" data-target="#conversionPerformance">
                                      <span>Hide</span>
                                     <span>Show</span>
                                </span>
                </div>

              </div>
              <div class="box_content collapse show" id="conversionPerformance">
                <template v-if="loader">
                  <clip-loader :color="color" :size="size"></clip-loader>
                </template>
                <template v-else-if="activity.conversions" style="width: 100%;">
                  <highcharts :options="conversions_chart" ref="conversions_chart"></highcharts>
                </template>
                <template v-else>
                  <no-events-available></no-events-available>
                </template>
              </div>
            </div>

          </div>
        </div>
        <div class="d-flex align-items-start ">
          <div v-if="analytics_type!='bio_links'" class="pb-4 white_box pr-3" style="width: 100%;">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Visitors By Browsers</h2>

                </div>
                <!--              <div class="right ml-auto">-->
                <!--                                <span class="toggle_link" data-toggle="collapse" data-target="#browserTable">-->
                <!--                                      <span>Hide</span>-->
                <!--                                     <span>Show</span>-->
                <!--                                </span>-->
                <!--              </div>-->
              </div>
              <div class="box_content collapse show" id="browserTable">
                <browsers-table-list :statistics="statistics" :paginationChunk='5'></browsers-table-list>
              </div>
            </div>
          </div>
          <div v-if="analytics_type!='bio_links'" class="pb-4 white_box pl-3" style="width: 100%;">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Visitors By Operating System</h2>
                </div>
                <!--              <div class="right ml-auto">-->
                <!--                                                <span class="toggle_link" data-toggle="collapse" data-target="#OSTable">-->
                <!--                                                      <span>Hide</span>-->
                <!--                                                     <span>Show</span>-->
                <!--                                                </span>-->
                <!--              </div>-->
              </div>
              <div class="box_content collapse show" id="OSTable">
                <os-table-list :statistics="statistics" :paginationChunk='5'></os-table-list>
              </div>
            </div>
          </div>
        </div>

        <div v-if="analytics_type!='bio_links'" class="pb-4 white_box ">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center">
              <div class="left">
                <h2>Visitors By Country</h2>

              </div>
              <div class="right ml-auto">
                                <span class="toggle_link" data-toggle="collapse"
                                      data-target="#countryTable">
                                      <span>Hide</span>
                                     <span>Show</span>
                                </span>
              </div>

            </div>
            <div class="box_content collapse show" id="countryTable">
              <div class="    relative">
                <template
                  v-if="visited_from_countries_chart.series[0] && visited_from_countries_chart.series[0].data.length > 0">
                  <highcharts :options="visited_from_countries_chart"
                              ref="referralChart"></highcharts>
                </template>

              </div>
              <countries-table-list :statistics="statistics"></countries-table-list>
            </div>
          </div>

        </div>

        <div v-if="analytics_type!='bio_links'" class="pb-4 white_box ">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center">
              <div class="left">
                <h2>Visitors by Source/Referrer</h2>
              </div>
              <div class="right ml-auto">
                                <span class="toggle_link" data-toggle="collapse" data-target="#visitorReferral">
                                      <span>Hide</span>
                                     <span>Show</span>
                                </span>
              </div>

            </div>
            <div class="box_content collapse show" id="visitorReferral">
              <div class="    relative">
                <template
                  v-if="visited_from_domains_chart.series[0] && visited_from_domains_chart.series[0].data.length > 0">
                  <highcharts :options="visited_from_domains_chart"
                              ref="referralChart"></highcharts>
                </template>
              </div>
              <visited-from-table-list :statistics="statistics"></visited-from-table-list>

            </div>
          </div>

        </div>

      </div>

    </transition>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>

<script>

import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import CountriesGraph from './CountriesViewForAnalytics.vue'
import OSTableList from './tables/OSTableList'
import BrowsersTableList from './tables/BrowsersTableList'
import CountriesTableList from './tables/CountriesTableList'
import VisitedFromTableList from './tables/VisitedFromTableList'
import {
  DARK_PURPLE_LOADER_COLOR,
  overviewOfClicksPerformance,
  overviewOfConversionsPerformance,
  pieVisitedCountriesChart,
  pieVisitedFromChart
} from '../../../../common/constants'

let clicksPerformanceChart = overviewOfClicksPerformance()
clicksPerformanceChart.series[1] = {
  name: 'Unique Clicks',
  data: [],
  color: '#d6f6fe',
  shadow: {
    width: 4,
    color: '#d6f6fe'
  },
  threshold: null,
  fillColor: {
    linearGradient: {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: [
      [0, '#d6f6fe'],
      [1, '#d6f6fe']
    ]
  }
}

export default ({
  props: {
    statistics: {},
    loader: {},
    analytics_type: null
  },
  components: {
    Dropdown,
    CountriesGraph,
    'os-table-list': OSTableList,
    BrowsersTableList,
    CountriesTableList,
    VisitedFromTableList
  },
  data: function () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      clicks_chart: clicksPerformanceChart,
      conversions_chart: overviewOfConversionsPerformance(),
      visited_from_countries_chart: pieVisitedCountriesChart(),
      visited_from_domains_chart: pieVisitedFromChart(),
      os_sorting: 'clicks_desc',
      country_sorting: 'clicks_desc',
      referral_sorting: 'clicks_desc',
      activity: {
        clicks: true,
        conversions: true,
        countries: true,
        referral: true

      }

    }
  },
  mounted() {
    const performanceHistory = this.statistics.aggregations.performance_history.buckets
    this.performanceChart(performanceHistory, this.activity, this.conversions_chart, this.clicks_chart, true)
    if (this.analytics_type != 'bio_links') {
      this.visitedDomainsChart()
      this.countriesListChart()
    }
  },

  created() {
    this.fetchLinks()
  },

  computed: {

    ...mapGetters([]),
    getStatisticsConversionRate() {
      if (this.analytics_type != 'bio_links') {
        return this.computeConversionRate(this.statistics.aggregations)
      }
    }
  },

  methods: {

    ...mapActions(['countLinks', 'fetchBrandList', 'fetchCampaignsList', 'fetchLinks']),
    visitedDomainsChart() {
      let visitedFromDomains = []

      this.statistics.aggregations.visited_from_domains.buckets.forEach(item => {
        visitedFromDomains.push([item.key, item.doc_count])
      })
      this.referral_item = visitedFromDomains.length

      this.visited_from_domains_chart.series =
        [{
          name: 'Visits',
          data: visitedFromDomains
        }]
    },
    countriesListChart() {
      let countries = []

      this.statistics.aggregations.countries.buckets.forEach(item => {
        if (item.key && item.key.trim().length > 3) countries.push([item.key, item.doc_count])
      })
      this.countries_item = countries.length
      this.visited_from_countries_chart.series =
        [{
          name: 'Country',
          data: countries
        }]
    }

  }
})
</script>
