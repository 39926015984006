<template>
    <div>
        <highmaps :options="options"></highmaps>
    </div>
</template>
<script>
import { isoCountries } from '../../../../config/isoCountries.js'
export default {
  props: {
    metrics_analyzed: {
      type: Object,
      default () {
        return {
          trafic: []
        }
      }
    }
  },
  data () {
    return {
      options: {
        credits: {
          enabled: false
        },
        chart: {

          borderWidth: 0,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          height: 300
        },
        title: {
          text: '',
          margin: 50,
          y: 30,
          style: {
            color: 'rgba(100, 113, 124, 0.66)'
          }
        },
        tooltip: {
          headerFormat: '<div class="engagement_tooltip">',
          pointFormat: '<h4>{point.name}</h4><p class="engagement_point"><span>{point.value} Traffic</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0
        },
        colorAxis: {
          min: 0,
          stops: [
            [0.25, '#c1e6e4'],
            [0.5, '#a3e6e4'],
            [0.75, '#92e6e0'],
            [1, '#6CE6DD']
          ]
        }
      }
    }
  },
  created () {
    console.debug(this.metrics_analyzed)
    this.options.series = [{
      name: 'Country',
      credits: {
        enabled: false
      },
      mapData: Highcharts.maps['custom/world'],
      data: this.finalizeData(this.metrics_analyzed.trafic),
      color: '#6CE6DD',
      nullColor: '#fafafa',
      states: {
        hover: {
          color: '#98eee8'
        }
      },
      animation: {
        duration: 1000
      }
    }]
  },
  methods: {
    finalizeData (data) {
      let final_data = []
      let me = this
      data.forEach(function (entry) {
        try {
          final_data.push([me.getCountryCode(entry['country']), entry['doc_count']])
        } catch (m) {
          console.debug(m)
        }
      })
      return final_data
    },
    getCountryCode (country) {
      let inverseCountryCodes = {}
      for (let i in isoCountries) {
        inverseCountryCodes[isoCountries[i]] = i
      }
      return inverseCountryCodes[country].toLowerCase()
    }
  }
}
</script>
